import React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

function useGtagReportConversionTel() {
  if (typeof gtag !== 'undefined') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-350833759/KOkVCJPj0IMDEN-YpacB'
    });
  }
  window.location.href = 'tel:+491639272103'
}
function useGtagReportConversionMail() {
  if (typeof gtag !== 'undefined') {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-350833759/KOkVCJPj0IMDEN-YpacB'
    });
  }
  window.location.href = 'mailto:stephan@verhaltenstherapie-haidhausen.de'
}

const ContactPage = () => (
  <Layout>
    <Seo
      title="Anfahrt & Kontakt | Verhaltenstherapie München Haidhausen"
      description="Psychotherapie München, Comeniusstraße 1. Kontakt: stephan@verhaltenstherapie-haidhausen.de | 0163 927 21 03. Freie Therapieplätze auf Anfrage."
    />
    <div className="relative">
      <div className="w-full h-96">
        <StaticImage className="w-full h-full" src="../assets/images/psychotherapie-haidhausen.jpg" alt="" />
        <div className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
          <div className="w-full text-center text-white">
            <h1 className="lg:text-4xl">Kontakt</h1>
            {/* <div>Haidhausen</div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="mt-8 mb-2 md:my-12 boxed max-w-prose generic-page">
      <p>Die ruhig gelegene Praxis befindet sich in Haidhausen nahe dem Bordeauxplatz in wunderschön gestalteten Altbauräumen nahe den Haltestellen Rosenheimer Platz (S-Bahn), Ostbahnhof (S-Bahn/U-Bahn/Zug), Max-Weber-Platz (U-Bahn) und Wörthstraße (Tram). Im Hof stehen kostenfreie Parkplätze zur Verfügung. Die Praxisräume befinden sich im 2. Stock (ohne Aufzug).</p>
    </div>
    <div className="grid max-w-6xl gap-1 mx-auto md:grid-cols-2 md:h-screen md:gap-48 ">
        <div className="flex items-center md:order-2">
            <div className="w-full p-4 space-y-4 sm:max-w-md sm:mx-auto">
              <div><strong className="uppercase">Adresse:</strong><br /><address className="not-italic">Comeniusstraße&nbsp;1 <br />81667 München</address><a className="text-gray-700 hover:underline" href="https://goo.gl/maps/hCd67LAT1mqCSAqE7">In Google Maps öffnen</a></div>
              <div className="col-span-1"><strong className="uppercase">Email:</strong><br /><button className="text-left text-gray-700 hover:underline " onClick={useGtagReportConversionMail}>stephan@verhaltenstherapie-haidhausen.de</button></div>
              <div className="lg:col-span-2"><strong className="uppercase">Telefon:</strong><br /><button className="text-gray-700 hover:underline" onClick={useGtagReportConversionTel}>+49 163 927 21 03</button><p className="mt-2">Sollte ich gerade nicht erreichbar sein, sprechen Sie mir bitte auf den Anrufbeantworter. Ich rufe in der Regel innerhalb eines Werktages zurück.</p></div>
            </div>
        </div>
        <div className="flex items-center p-4 bg-red-100">
            <StaticImage className="max-w-md mx-auto transform md:translate-x-32" src="../assets/images/arm-chair-therapy.jpg" alt="" />
        </div>
    </div>
    <div className="my-12 boxed max-w-prose generic-page">
      <h2 className="h3">Hinweis:</h2>
      <p>COVID 19: Sitzungen vor Ort sowie per Video-Konferenz über zertifizierte Videoanbieter mit Ende-zu-Ende-Verschlüsselung möglich.</p>
    </div>
  </Layout>
)

export default ContactPage